<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <b-card no-body>
            <div class="m-2">
            </div>
            <div class="mx-2 mb-2">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button :to="{name: 'arrival-create'}" v-if="$can('create', 'arrivals')" variant="primary">
                      <span class="text-nowrap">Новый приход</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ arrivals.from }} по {{ arrivals.to }} из {{ arrivals.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="arrivals.total"
                      :per-page="arrivals.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <!---->
                <tr>
                  <th>#</th>
                  <th>Дата</th>
                  <th v-if="$can('read', 'users')">Поставщик</th>
                  <th>Статус</th>
                  <th>К-во</th>
                  <th>Вес (кг)</th>
                  <th v-if="$can('read', 'payments')">Сумма</th>
                  <th v-if="$can('manage', 'arrivals')">Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="arrival in arrivals.data" :key="arrival.id">
                  <td>
                    <router-link :to="{ name: 'arrival-details', params: { id: arrival.id }}" class="font-weight-bold">
                      #{{ arrival.id }}
                    </router-link>
                  </td>
                  <td>{{ $formatters.formattedDate(arrival.created_at, 'dd.MM.yy HH:mm') }}</td>
                  <td v-if="$can('read', 'users')">{{ arrival.user.name }} {{ arrival.user.surname }} <br>
                    <small>({{ arrival.user.company_name }})</small>
                  </td>
                  <td>
                    <span
                        :class="['badge badge-pill', arrival.status.id === 1 ? 'badge-light-primary' : 'badge-light-success']"
                    >
                      {{ arrival.status.name }}
                    </span>
                  </td>
                  <td>{{ arrival.qty }}</td>
                  <td>{{ arrival.weight / 1000 }}</td>
                  <td v-if="$can('read', 'payments')">
                    <span class="amount" v-for="(item, index) in arrival.sum" :key="index">
                      <span class="amount">{{ item.amount }}</span> <span class="currency">{{ item.currency }}</span>  <br/>
                    </span>
                  </td>
                  <td v-if="$can('manage', 'arrivals')">
                    <button
                        v-if="$can('manage', 'arrivals') && arrival.status.id !== 2"
                        @click="deleteArrival(arrival.id)"
                        class="btn mr-1 btn-danger"
                    >
                      Удалить
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ arrivals.from }} по {{ arrivals.to }} из {{ arrivals.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="arrivals.total"
                      :per-page="arrivals.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-card>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BPagination,
} from 'bootstrap-vue';

export default {
  name: 'Arrivals',
  components: {
    BCard, BButton, BPagination,
  },
  data() {
    return {
      currentPage: 1,
      arrivals: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
    };
  },
  watch: {
    async currentPage(newValue) {
      this.arrivals.current_page = newValue;
      await this.getArrivals();
    },
  },
  async mounted() {
    await this.getArrivals();
  },
  methods: {
    async getArrivals() {
      this.arrivals = (await this.$api.arrivals.list({
        ...(this.arrivals.current_page > 1 && { page: this.arrivals.current_page }),
        limit: 25,
      })).data;
    },
    async deleteArrival(id) {
      this.$swal({
        title: 'Вы уверены?',
        text: 'Вы не сможете это вернуть назад!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, подтверждаю!',
        cancelButtonText: 'Нет, отмена!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$api.arrivals.delete(id);
          await this.getArrivals();
          this.$swal({
            icon: 'success',
            title: 'Удалено!',
            text: 'Приход был успешно удален.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
